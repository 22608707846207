.datatable-crud .table-header {
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.badge {
 border-radius: 2px;
 padding: 0.25em 0.5rem;
 text-transform: uppercase;
 font-weight: 700;
 font-size: 12px;
 letter-spacing: 0.3px;
}

.status-active,
.status-sent,
.status-manager,
.status-finished {
 background: #c8e6c9;
 color: #256029;
}

.status-inactive,
.status-deleted,
.status-canceled {
 background: #ffcdd2;
 color: #c63737;
}

.status-new,
.status-admin {
 background-color: #b3e5fc;
 color: #23547b;
}

.status-preparing,
.status-operator {
 background-color: #feedaf;
 color: #8a5340;
}

.status-return {
 background-color: #eccfff;
 color: #694382;
}

@media screen and (max-width: 960px) {
 .datatable-crud .table-header {
  align-items: flex-start;
 }
}
.datatable-crud .p-dialog {
 width: 150px;
 margin: 0 auto 2rem auto;
 display: block;
}
.datatable-crud .confirmation-content {
 display: flex;
 align-items: center;
 justify-content: center;
}

@media screen and (max-width: 960px) {
 .datatable-crud .p-toolbar {
  flex-wrap: wrap;
 }
 .datatable-crud .p-toolbar .p-button {
  margin-bottom: 0.25rem;
 }
 .datatable-crud .table-header {
  flex-direction: column;
 }
 .datatable-crud .table-header .p-input-icon-left,
 .datatable-crud .table-header input {
  width: 100%;
 }
}
